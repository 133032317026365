import { Routes } from '@angular/router';
import { AuthGuard } from './services/strapi/auth/ng-strapi-auth.guard';
import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './login/login.component';

import { StatisticsComponent } from './dashboard/statistics/statistics.component';
import { RegisterComponent } from './login/register/register.component';
import { ForgotComponent } from './login/forgot/forgot.component';
import { ConfirmEmailComponent } from './login/confirm-email/confirm-email.component';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { PrivacyComponent } from './terms/privacy/privacy.component';
import { ServiceTermsComponent } from './terms/service-terms/service-terms.component';
import { EmptyComponent } from './layouts/empty/empty.component';
import { GoogleComponent } from './login/external/google/google.component';
import { NgStrapiTrainerAuthGuard } from './services/strapi/auth/ng-strapi-trainer-auth.guard';
import { SettingsComponent } from './dashboard/settings/settings.component';
// import {VenueSettingsComponent} from './dashboard/statistics/venue-settings/venue-settings.component';
import { AcceptInviteComponent } from './login/accept-invite/accept-invite.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { StandaloneLivePageComponent } from './dashboard/standalone-live-page/standalone-live-page.component';
import { LiveViewComponent } from './dashboard/live-view/live-view.component';
// import {DetailedMetricComponent} from './dashboard/statistics/detailed-stat/detailed-metric.component';
// import {TrainerComponent} from "./dashboard/trainer/trainer.component";

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'loading',
    pathMatch: 'full',
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      // {
      //     path: '',
      //     redirectTo: 'loading',
      //     pathMatch: 'prefix',
      //     // children: []
      // },
      {
        path: 'overview',
        component: StatisticsComponent,
        data: {
          title: 'Overview',
          topRightButtonType: 'dashboard',
          urls: [{ title: '', url: 'view' }],
        },
      },
      {
        path: 'statistics-weekly',
        component: StatisticsComponent,
        data: {
          title: 'Statistics',
          topRightButtonType: 'calendar',
          urls: [{ title: '', url: 'view' }],
        },
      },
      {
        path: 'statistics-daily',
        component: StatisticsComponent,
        data: {
          title: 'Statistics',
          topRightButtonType: 'calendar',
          urls: [{ title: '', url: 'view' }],
        },
      },
      {
        path: 'statistics-ingredients',
        component: StatisticsComponent,
        data: {
          title: 'Statistics',
          topRightButtonType: 'calendar',
          urls: [{ title: '', url: 'view' }],
        },
      },
      {
        path: 'statistics-trends',
        component: StatisticsComponent,
        data: {
          title: 'Trends Statistics',
          topRightButtonType: 'calendar',
          urls: [{ title: '', url: 'view' }],
        },
      },
      {
        path: 'statistics-cross',
        component: StatisticsComponent,
        data: {
          title: 'Cross Statistics',
          topRightButtonType: 'calendar',
          urls: [{ title: '', url: 'view' }],
        },
      },
      {
        path: 'statistics-export',
        component: StatisticsComponent,
        data: {
          title: 'Export Statistics',
          topRightButtonType: 'calendar',
          urls: [{ title: '', url: 'view' }],
        },
      },
      {
        path: 'competition',
        component: StatisticsComponent,
        data: {
          title: 'Competition',
          topRightButtonType: 'calendar',
          urls: [{ title: '', url: 'view' }],
        },
      },
      {
        path: 'live-view',
        component: LiveViewComponent,
        data: {
          title: 'Live View',
          // topRightButtonType: 'full-screen',
          urls: [{ title: '', url: 'view' }],
        },
      },
      {
        path: 'settings',
        component: SettingsComponent,
        data: {
          title: 'Settings',
          urls: [{ title: '', url: 'view' }],
        },
      },
      // {
      //     path: 'live-view',
      //     component: LiveViewComponent,
      //     data: {
      //         title: 'Statistics',
      //         urls: [
      //             { title: '' , url: 'view'}
      //         ]
      //     },
      // },
      // {
      //     path: 'demo',
      //     component: OverviewComponent,
      //     data: {
      //         title: 'Demo',
      //         urls: [
      //             { title: '' , url: 'view'}
      //         ]
      //     },
      // },
      // {
      //     path: 'mod',
      //     component: OverviewModComponent,
      //     data: {
      //         title: 'Made Of Dough',
      //         urls: [
      //             { title: '' , url: 'view'}
      //         ]
      //     },
      // },
      // {
      //     path: 'session-list',
      //     component: SessionListComponent,
      //     data: {
      //         title: 'Тренировки',
      //         urls: [
      //             { title: '' , url: 'view'}
      //         ]
      //     },
      // },
      // {
      //     path: 'subscription/list',
      //     component: SubscriptionListComponent,
      //     data: {
      //         title: 'Subscription List',
      //         urls: [
      //             { title: 'List' , url: 'view'}
      //         ]
      //     },
      // },
      // {
      //     path: 'subscription',
      //     component: BuySubscriptionComponent,
      //     data: {
      //         title: 'Подписка',
      //         urls: [
      //             { title: '' , url: 'view'}
      //         ]
      //     },
      // },
      // {
      //     path: 'profile',
      //     component: UserProfileComponent,
      //     data: {
      //         title: 'Мой Профиль',
      //         urls: [
      //             { title: '' , url: 'view'}
      //         ]
      //     },
      // },
      // {
      //     path: 'campaign/:id',
      //     component: CampaignViewerComponent,
      //     data: {
      //         title: 'Campaign',
      //         urls: [
      //             { title: 'Campaigns List' , url: '/campaign'},
      //             { title: 'Campaign View' }
      //         ]
      //     }
      // },
    ],
  },
  // {
  //     path: 'venue-settings/:id',
  //     component: VenueSettingsComponent,
  //     canActivate: [AuthGuard],
  // },
  {
    path: 'loading',
    component: LoadingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'standalone-live-view',
    component: StandaloneLivePageComponent,
  },
  // {
  //     path: 'statistics/:venueId/:statId',
  //     component: DetailedMetricComponent,
  //     canActivate: [AuthGuard],
  // },
  // {
  //     path: 'booking/:type',
  //     canActivate: [AuthGuard],
  //     component: BookSessionComponent,
  // },
  // {
  //     path: 'client-questionnaire',
  //     canActivate: [AuthGuard],
  //     component: AppBlankComponent,
  //     children: [
  //         {
  //             path: '',
  //             component: ClientQuestionnaireComponent,
  //         },
  //     ]
  // },
  // {
  //     path: 'redirect',
  //     canActivate: [AuthGuard],
  //     component: AppBlankComponent,
  //     children: [
  //         {
  //             path: 'session-selector',
  //             component: BookSessionSelectorComponent,
  //             // redirectTo: "login",
  //             // pathMatch: 'full'
  //         },
  //     ]
  //     // redirectTo: "login",
  //     // pathMatch: 'full'
  // },
  // {
  //     path: 'book-session',
  //     canActivate: [AuthGuard],
  //     component: BookSessionSelectorComponent,
  //     // redirectTo: "login",
  //     // pathMatch: 'full'
  // },
  // {
  //     path: 'trainer',
  //     canActivate: [NgStrapiTrainerAuthGuard],
  //     component: FullTrainerComponent,
  //     children: [
  //         {
  //             path: '',
  //             redirectTo: 'sessions',
  //             pathMatch: 'full'
  //         },
  //         {
  //             path: 'sessions',
  //             component: SessionsComponent,
  //             data: {
  //                 title: 'Тренировки',
  //                 urls: [
  //                     { title: '' , url: 'view'}
  //                 ]
  //             },
  //         }
  //     ]
  // },

  {
    path: 'auth/external/google',
    component: GoogleComponent,
  },
  {
    path: 'auth/external/facebook',
    component: GoogleComponent,
  },
  {
    path: '',
    component: EmptyComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'privacy',
        component: PrivacyComponent,
      },
      {
        path: 'terms-of-service',
        component: ServiceTermsComponent,
      },

      {
        path: 'authentication/register',
        component: RegisterComponent,
      },
      {
        path: 'authentication/accept-invite',
        component: AcceptInviteComponent,
      },
      {
        path: 'authentication/forgot',
        component: ForgotComponent,
      },
      {
        path: 'authentication/confirm-email',
        component: ConfirmEmailComponent,
      },
      {
        path: 'authentication/change-password',
        component: ChangePasswordComponent,
      },
    ],
  },
  // {
  //     path: '**/',
  //     // redirectTo: '/mod',
  //     redirectTo: 'loading',
  //
  // },
  {
    path: '**',
    redirectTo: 'loading',
  },
];
