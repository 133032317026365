import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  userGroups?: any[];
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  userGroups?: any[];
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  // {
  //   state: 'starter',
  //   name: 'Starter Page',
  //   type: 'link',
  //   icon: 'content_copy'
  // },
  // {
  //   state: 'influencer',
  //   name: 'Influencers',
  //   type: 'sub',
  //   icon: 'analytics',
  //   children: [
  //     { state: 'list', name: 'List', type: 'link' },
  //       // { state: 'view', name: 'View', type: 'link' },
  //       ]
  // },
  // {
  //   state: 'Venue comparison',
  //   name: 'Venue comparison',
  //   type: 'link',
  //   icon: 'compare'
  // },
  // {
  //   state: 'influencer',
  //   name: 'Influencers',
  //   type: 'link',
  //   icon: 'analytics'
  // },
  {
    state: 'brand-logo',
    name: 'Brand Logo',
    type: 'info-img',
    icon: 'chat',
    userGroups: [
      'brand_admin',
      'venue_manager',
      'live_venue_viewer',
      'area_manager',
    ],
  },
  {
    state: 'overview',
    name: 'Overview',
    type: 'link',
    icon: 'dashboard',
    userGroups: ['brand_admin', 'venue_manager', 'area_manager'],
  },
  {
    state: 'statistics-weekly',
    name: 'Weekly Insights',
    type: 'link',
    icon: 'calendar_view_week',
    userGroups: ['brand_admin', 'venue_manager', 'area_manager'],
  },
  {
    state: 'statistics-daily',
    name: 'Daily Insights',
    type: 'link',
    icon: 'calendar_view_day',
    userGroups: ['brand_admin', 'venue_manager', 'area_manager'],
  },
  {
    state: 'statistics-ingredients',
    name: 'Ingredients',
    type: 'link',
    icon: 'bento',
    userGroups: ['brand_admin', 'venue_manager', 'area_manager'],
  },


  {
    state: '',
    name: 'Analytics & Insights',
    type: 'sub',
    icon: 'insights',
    children: [
      {
        state: 'statistics-cross',
        name: 'Venue Comparison',
        type: 'link',
        icon: 'compare',
        userGroups: ['brand_admin', 'area_manager'],
      },
      {
        state: 'statistics-trends',
        name: 'Venue Trends',
        type: 'link',
        icon: 'trending_up',
        userGroups: ['brand_admin', 'venue_manager', 'area_manager'],
      },
      {
        state: 'statistics-export',
        name: 'Exporting',
        type: 'link',
        icon: 'download',
        userGroups: ['brand_admin', 'area_manager', 'venue_manager'],
      },
    ]
  },

  {
    state: '',
    name: 'Live Views',
    type: 'sub',
    icon: 'stream',
    children: [
      {
        state: 'competition',
        name: 'Competition',
        type: 'link',
        icon: 'star',
        userGroups: ['brand_admin', 'area_manager', 'venue_manager'],
      },


      {
        state: 'live-view',
        name: 'Live Screen',
        type: 'link',
        icon: 'bolt',
        userGroups: ['brand_admin', 'venue_manager', 'live_venue_viewer', 'area_manager']
      },
    ]
  },
  {
    state: 'settings',
    name: 'Settings',
    type: 'link',
    icon: 'settings',
    userGroups: [
      'brand_admin',
      'venue_manager',
      'live_venue_viewer',
      'area_manager',
    ],
  },
  {
    state: 'logout',
    name: 'Logout',
    type: 'func',
    icon: 'logout',
    // userGroups: ['brand_admin', 'venue_manager', 'live_venue_viewer']
  },
  // {
  //   state: 'saperator',
  //   name: 'test',
  //   type: 'saperator',
  //   icon: 'account_box',
  //   // userGroups: ['brand_admin', 'venue_manager', 'live_venue_viewer']
  // },
  // {
  //   state: 'https://go.crisp.chat/chat/embed/?website_id=b0cc031d-f219-42f1-bb3b-8ef4094c0d01',
  //   name: 'Чат с поддержкой',
  //   type: 'extTabLink',
  //   icon: 'chat'
  // },
  {
    state: 'https://eyezilla.ai',
    name: '© Eyezilla, ' + new Date().getFullYear(),
    type: 'info',
    icon: 'chat',
    // userGroups: ['brand_admin', 'venue_manager', 'live_venue_viewer']
  },
  // {
  //   state: 'material',
  //   name: 'Material Ui',
  //   type: 'sub',
  //   icon: 'bubble_chart',
  //   badge: [{ type: 'red', value: '17' }],
  //   children: [
  //     { state: 'badge', name: 'Badge', type: 'link' },
  //     { state: 'button', name: 'Buttons', type: 'link' },
  //     { state: 'cards', name: 'Cards', type: 'link' },
  //     { state: 'grid', name: 'Grid List', type: 'link' },
  //     { state: 'lists', name: 'Lists', type: 'link' },
  //     { state: 'menu', name: 'Menu', type: 'link' },
  //     { state: 'tabs', name: 'Tabs', type: 'link' },
  //     { state: 'stepper', name: 'Stepper', type: 'link' },
  //     { state: 'ripples', name: 'Ripples', type: 'link' },
  //     { state: 'expansion', name: 'Expansion Panel', type: 'link' },
  //     { state: 'chips', name: 'Chips', type: 'link' },
  //     { state: 'toolbar', name: 'Toolbar', type: 'link' },
  //     { state: 'progress-snipper', name: 'Progress snipper', type: 'link' },
  //     { state: 'progress', name: 'Progress Bar', type: 'link' },
  //     { state: 'dialog', name: 'Dialog', type: 'link' },
  //     { state: 'tooltip', name: 'Tooltip', type: 'link' },
  //     { state: 'snackbar', name: 'Snackbar', type: 'link' },
  //     { state: 'slider', name: 'Slider', type: 'link' },
  //     { state: 'slide-toggle', name: 'Slide Toggle', type: 'link' }
  //   ]
  // }
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
