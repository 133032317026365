import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { sortByName } from '../utils/utils';
import { HttpClient } from '@angular/common/http';
import { NgStrapiAuthConfig } from './strapi/auth/ng-strapi-auth-config';
import moment from 'moment';
import xlsx from 'json-as-xlsx';

@Injectable({
  providedIn: 'root',
})
export class RestaurantsService {
  // public forcedStartDate = '29.08.2020';

  allVenuesSubject = new BehaviorSubject([]);
  selectedVenueSubject = new BehaviorSubject(null);
  selectedVenueDataSubject = new BehaviorSubject(null);
  rightSidebarSubject = new Subject<any>();

  brandVenueQuerySubscription;
  selectedGrafanaId: string;
  latestDataDate: moment.Moment;
  selectedVenue: string;

  private venueData: any;
  private allVenues = [];
  private apiUrl: string;

  public get allVenuesData() {
    return this.allVenues;
  }

  private statisticParamsBackup: object = {
    daily: {},
    weekly: {},
    ingredients: {},
    live: {},
    trends: {},
    cross: {},
    export: {},
    overview: {},
    competition: {},
  };

  public get statisticParamsBackupData() {
    return this.statisticParamsBackup;
  }

  public set statisticParamsBackupData(val) {
    this.statisticParamsBackup = { ...this.statisticParamsBackup, ...val };
  }

  constructor(
    private httpClient: HttpClient,
    @Inject('config') private config: NgStrapiAuthConfig
  ) {
    if (this.config && this.config.apiUrl) {
      this.apiUrl = this.config.apiUrl;
    } else {
      const err = '[NgStrapiAuth]: no api url provided';
      console.error(err);
      throw new Error('[NgStrapiAuth]: no api url provided');
    }
    this.fetchBrandVenues();

    // Try to reload the service until data has arrived
    setTimeout(() => {
      if (!this.venueData) {
        window.location.reload();
      }
    }, 10000);
  }

  async fetchBrandVenues() {
    try {
      const data: any = await this.httpClient
        .post(this.apiUrl + '/brands/getBrandVenues', {})
        .toPromise();
      console.group('RestauratService');
      // receivedData = true;
      console.log('data', data);
      const defaultChartSettings = data.self.brand.defaultChartsSettings;
      console.log('defaultChartSettings', defaultChartSettings);
      console.groupEnd();

      if (data.venues) {
        data.venues = data.venues.map((venue) => {
          venue.chartsSettings = venue.chartsSettings
            ? venue.chartsSettings
            : defaultChartSettings;
          return venue;
        });
        this.allVenues = data.venues;
        console.log('this.allVenues', this.allVenues);

        this.allVenues.sort(sortByName);
        this.allVenuesSubject.next(this.allVenues);
        console.log(
          'data.self.dasboard_setting.defaultVenue.id',
          data.self.dasboard_setting.defaultVenue.id
        );
        const venueStillExists = this.allVenues.find(
          (venue) => venue.id === data.self.dasboard_setting.defaultVenue.id
        );
        console.log('venueStillExists', venueStillExists);
        if (venueStillExists) {
          this.processVenueUpdates(
            data.self.dasboard_setting.defaultVenue.id,
            false
          );
        } else {
          if (this.allVenues.length > 0) {
            this.processVenueUpdates(this.allVenues[0].id);
          }
        }
      }
      // this.loading.brandVenues = loading;
      // this.brandVenueQuerySubscription.unsubscribe();
    } catch (e) {
      console.log('e', e);
    }
  }

  refetchBrandVenues() {
    console.log('refetchBrandVenues()');
    this.fetchBrandVenues();
    // this.brandVenueQuery.refetch();
  }

  async processVenueUpdates(venueId, updateDefaultVenue = true) {
    this.selectedVenue = venueId;
    this.venueData = this.allVenues.find(
      (venueData) => String(venueData.id) === String(this.selectedVenue)
    );
    this.selectedVenueSubject.next(this.selectedVenue);
    this.selectedVenueDataSubject.next(this.venueData);
    if (updateDefaultVenue) {
      const data: any = await this.httpClient
        .post(this.apiUrl + '/dasboard-settings/updateDefaultVenue', {
          id: venueId,
        })
        .toPromise();
    }
  }

  toggleRightSidebar(data) {
    this.rightSidebarSubject.next(data);
  }

    exportXLSX(jsonData, fileName) {
    const preBuiltData = [];
    Object.keys(jsonData.grafanaData).forEach((key) => {
      const sheetData = jsonData.grafanaData[key];
      const sheet = {
        sheet: key,
        columns: Object.keys(sheetData[0]).map(columnName => {
          return { label: columnName, value: columnName };
        }),
        content: sheetData
      };
        preBuiltData.push(sheet);
    });


      const settings = {
        fileName: fileName, // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: 'writeFile', // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {
          cellDates: true,
        }, // Style options from https://docs.sheetjs.com/docs/api/write-options
      };

      xlsx(preBuiltData, settings); // Will download the excel file
    }
}
